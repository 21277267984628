/* eslint-disable max-len */
// @flow

export const FILE_DEFAULT: FileURL = {
	remoteURL: '',
	file: { name: '', url: '' },
	selected: 'remoteURL',
};
export const IS_DEVEL = process.env.NODE_ENV !== 'production';

export const SHARE_CONSTANTS = {
	WHATSAPP: 'WhatsApp',
	TWITTER: 'Twitter',
	FACEBOOK: 'Facebook',
};

export const COLLECTIONS = {
	SETTINGS: 'settings',
	LEADS: 'leads',
	TEACHER_LEADS: 'teacher-leads',
};

export const PLACEHOLDERS_TEXTS = {
	fullName: 'שם מלא',
	phoneNumber: 'טלפון של המורה',
	email: 'כתובת המייל של המורה',
	teacherSubjects: 'המקצועות שהמורה מלמד/ת',
	schoolName: 'ביה"ס שבו מלמד/ת כיום',
	locality: 'יישוב',
	opinion: 'מדוע לדעתך המורה ראוי/ה לקבל את פרס "המורה של המדינה"?',
	recommenderFullName: 'שם מלא',
	recommenderPhone: 'טלפון',
	recommenderEmail: 'כתובת מייל',
	furtherRecommendFullName1: 'שם מלא',
	furtherRecommendPhone1: 'טלפון',
	furtherRecommendFullName2: 'שם מלא',
	furtherRecommendPhone2: 'טלפון',
};

export const ERRORS_TEXTS = {
	fullName: 'יש למלא שם מלא של המורה',
	phoneNumber: 'יש למלא מספר טלפון של המורה',
	phoneNumberNotValid: 'יש למלא מספר טלפון של המורה',
	email: 'יש למלא כתובת מייל',
	emailNotValid: 'יש למלא כתובת מייל תקינה',
	teacherSeniority: 'יש לבחור את הותק של המורה',
	teacherSubjects: 'יש למלא את המקצועות שהמורה מלמד/ת ',
	schoolName: 'יש למלא את ביה"ס שבו מלמד/ת כיום ',
	locality: 'יש למלא את שם היישוב',
	opinion: 'יש לכתוב מדוע המורה ראוי/ה לקבל את הפרס',
	opinionMaximum: 'יותר מ-500 מילים',
	recommenderFullName: 'יש למלא שם מלא של הממליץ/ה',
	recommenderPhone: 'יש למלא מספר טלפון תקין של הממליץ/ה',
	recommenderPhoneNotValid: 'יש למלא מספר טלפון תקין של הממליץ/ה',
	recommenderEmail: 'יש למלא כתובת מייל של הממליץ/ה',
	recommenderEmailNotValid: 'יש למלא כתובת מייל תקינה',
	recommenderRelationship: 'יש לבחור את הקשר שלך למורה',
	rateCriterias: 'יש לסמן ערך בין 1 ל 5',
	confirmCheckbox: 'יש לאשר קריאת התקנון',
	idNumber: 'יש למלא מספר תעודת זהות',
	birthDate: 'יש למלא תאריך לידה',
	address: 'יש לבחור כתובת מגורים (ישוב, רחוב, מספר בית)',
	study: 'יש למלא מקצועות שמלמדת היום',
	yearsOfTeaching: 'יש לבחור מספר שנות ותק בהוראה',
	schoolCity: 'יש למלא את יישוב ביה"ס',
	schoolPhone: 'יש למלא את מספר הטלפון של בית הספר',
	principalName: 'יש למלא שם המנהל',
	principalPhone: 'יש למלא מספר טלפון של המנהל',
	wrongPhoneStructure: 'יש למלא מספר טלפון תקין',
};

export const texts = {
	introTitle: 'אין על האוכל של סבתא שלי!',
};
