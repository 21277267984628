import React from 'react';
import moment from 'moment';
import cn from 'classnames';

import { DatePicker } from 'antd';

import css from './DatePicker.module.scss';

export const Picker = ({ label, input, defaultValue, placeholder }) => {
	return (
		<label className={cn(css.datePicker)}>
			<DatePicker
				className={css.picker}
				{...input}
				label={label}
				defaultValue={defaultValue}
				selected={null}
				placeholder={placeholder}
				suffixIcon={null}
				prefixCls={null}
				value={input.value ? moment(input.value) : null}
			/>
		</label>
	);
};
