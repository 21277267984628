// @flow

import * as React from 'react';
import classNames from 'classnames';
import logo1src from 'assets/client/images/logo1.png';
import logo2src from 'assets/client/images/logo2.png';
import logo3src from 'assets/client/images/logo3.png';
import logo4src from 'assets/client/images/logo4.png';
import logo5src from 'assets/client/images/logo5.png';

import { dataLayerEvents } from '../../../../../common/utils/gtag';
import AccessibilityIcon from '../AccessibilityIcon';

import Socials from '../Socials';
import * as css from './Header.scss';

type Props = {
	className?: string,
	isMobile: boolean,
};

const Header = ({ className, isMobile }: Props) => {
	const onLogoClick = (label: string) => (): void => {
		dataLayerEvents.onLogoClick(label);
	};

	return (
		<div className={classNames(css.header, className)} id="header">
			<div className={css.headerWrapper}>
				<div className={css.centerLogos}>
					<img src={logo1src} alt="logo1" className={css.logo1} onClick={onLogoClick('Yedioth Aharonot')} />
					<img src={logo2src} alt="logo2" className={css.logo2} onClick={onLogoClick('ynet')} />
					<img src={logo3src} alt="logo3" className={css.logo3} onClick={onLogoClick('MSD')} />
					<img src={logo4src} alt="logo4" className={css.logo4} onClick={onLogoClick('Teachers Union')} />
					<img src={logo5src} alt="logo5" className={css.logo5} onClick={onLogoClick('TLMA')} />
				</div>
				<div className={css.leftSide}>
					<div className={css.sociallsWrapper}>
						<Socials minified={isMobile} />
						<AccessibilityIcon />
					</div>
				</div>
			</div>
		</div>
	);
};

Header.defaultProps = {
	className: '',
};

export { Header };
