/* eslint-disable react/no-danger */
// @flow
import * as React from 'react';
import classNames from 'classnames';
import yitLogoSrc from 'assets/client/images/yit_logo.svg';
import * as css from './Footer.scss';

type Props = {
	className?: string,
};

const Footer = ({ className }: Props) => {
	const termOfUse = 'תנאי שימוש';
	const termOfUseLink = 'https://z.ynet.co.il/short/content/2018/privacypolicy/terms.html';
	const privacyPolicy = 'מדיניות פרטיות';
	const privacyPolicyLink = 'https://z.ynet.co.il/short/content/2018/privacypolicy/policy.html';
	const yit = 'עיצוב, אפיון ופיתוח';
	const yitLink = 'https://yit.co.il/';

	return (
		<footer className={classNames(css.footer, className)}>
			<div className={css.footerLinks}>
				<div className={css.footerLinksWrapper}>
					<div className={css.terms}>
						<a href={termOfUseLink} target="_blank" rel="noopener noreferrer">
							{termOfUse}
						</a>
					</div>
					<div className={css.privacy}>
						<a href={privacyPolicyLink} target="_blank" rel="noopener noreferrer">
							{privacyPolicy}
						</a>
					</div>
					<div className={css.yit}>
						<a href={yitLink} target="_blank" rel="noopener noreferrer">
							<span>{yit}</span>
							<img src={yitLogoSrc} alt="yit-logo" />
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
};

Footer.defaultProps = {
	className: '',
};

export { Footer };
