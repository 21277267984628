// @flow
import * as React from 'react';
import classNames from 'classnames';
import * as css from './NotStartedPopup.scss';

type Props = { className?: string };

const NotStartedPopup = ({ className }: Props) => {
	return (
		<div className={classNames(css.notStarted, className)}>
			<div className={classNames(css.notStartedWrapper)}>
				<p>
					<span>ההצבעה באתר תתחיל בקרוב</span>
				</p>
			</div>
		</div>
	);
};

NotStartedPopup.defaultProps = { className: '' };

export default NotStartedPopup;
