import { CAN_USE_DOM } from '../constants';

export const dataLayerEvents = {
	/**
	 * @param label {String}
	 */
	onShareBtnClick: label =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'Best teacher 2023',
			Action: 'Share',
			Label: label,
		}),
	onFormSended: label =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'Best teacher 2023',
			Action: 'Application Sent',
			Label: label,
		}),

	onLogoClick: label =>
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'Best teacher 2023',
			Action: 'Click on Logo',
			Label: label,
		}),
};
