// @flow
import * as React from 'react';
import { Field } from 'redux-form';

import Input from './TextField';

type Props = {
	name: string,
	inputRef?: { current: ?HTMLInputElement } | null,
	className?: string,
	placeholder?: any,
	eventListeners?: { [id: string]: Function },
	isTextarea?: boolean,
	textareaType?: number,
	inlineType?: boolean,
	disabled?: boolean,
	clearValue?: boolean,
	maxWordsLength?: number,
	isNumberField?: boolean,
	minNumber?: number,
	maxNumber?: number,
	isEmailField?: boolean,
	maxLength?: number,
	isPlusAtTheStart?: boolean,
};

export default class Text extends React.PureComponent<Props> {
	static defaultProps = {
		inputRef: null,
		className: '',
		placeholder: <></>,
		eventListeners: {},
		isTextarea: false,
		textareaType: 1,
		inlineType: false,
		disabled: false,
		clearValue: false,
		maxWordsLength: 1500,
		isNumberField: false,
		minNumber: 0,
		maxNumber: 120,
		isEmailField: false,
		maxLength: 10000,
		isPlusAtTheStart: false,
	};

	render() {
		const {
			name,
			className,
			placeholder,
			inputRef,
			eventListeners,
			isTextarea,
			textareaType,
			inlineType,
			disabled,
			clearValue,
			maxWordsLength,
			isNumberField,
			minNumber,
			maxNumber,
			isEmailField,
			maxLength,
			isPlusAtTheStart,
		} = this.props;
		const InputComponent = props => (
			<Input
				{...props}
				placeholder={placeholder}
				className={className}
				inputRef={inputRef}
				eventListeners={eventListeners}
				isTextarea={isTextarea}
				textareaType={textareaType}
				inlineType={inlineType}
				disabled={disabled}
				clearValue={clearValue}
				maxWordsLength={maxWordsLength}
				isNumberField={isNumberField}
				minNumber={minNumber}
				maxNumber={maxNumber}
				isEmailField={isEmailField}
				maxLength={maxLength}
				isPlusAtTheStart={isPlusAtTheStart}
			/>
		);

		return <Field {...this.props} name={name} component={InputComponent} />;
	}
}
