import React, { useRef } from 'react';

import css from './PasswordForm.module.scss';

export const PasswordForm = ({ onSubmit, isError }) => {
	const inputRef = useRef(null);

	const onSubmitClick = () => {
		if (onSubmit && inputRef.current) onSubmit(inputRef.current.value);
	};

	return (
		<div className={css.passwordForm}>
			<h3 className={css.title}>הזינו סיסמה בבקשה:</h3>
			<div className={css.field}>
				<input type="text" ref={inputRef} />
			</div>
			<div className={css.submit} onClick={onSubmitClick}>
				<span>שליחה</span>
			</div>
			{isError && <span className={css.error}>סיסמה שגויה , אנא נסה שנית</span>}
		</div>
	);
};
