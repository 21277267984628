/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
// @flow
import { connect } from 'react-redux';
import _ from 'lodash';
import * as React from 'react';
import classNames from 'classnames';
import validator from 'validator';

import { LEAD_CONSTANTS, CAN_USE_DOM } from 'common/constants';
import Icon from 'common/components/Icon';

import { PLACEHOLDERS_TEXTS, COLLECTIONS, ERRORS_TEXTS } from 'client/constants';
import { Form, TextField, Checkbox, Radio, Select, Responsive } from 'client/components/common';
import { initialize, SubmissionError, reset } from 'redux-form';
import { createItem } from 'client/actions';
import { dataLayerEvents } from 'common/utils/gtag';
import termsPdfFile from 'assets/client/2023terms.pdf';
import arrowLeft from 'assets/client/images/arrow-left.svg';
import ErrorsPopup from '../Popups/ErrorsPopup';
import SendedFormPopup from '../Popups/SendedFormPopup';
import * as css from './ContentForm.scss';
import AlreadyInSystemPopup from '../Popups/AlreadyInSystemPopup';
import { validateEmail } from '../../../utils/vallidateEmail';
import NotStartedPopup from '../Popups/NotStartedPopup';

// eslint-disable-next-line no-useless-escape
const phoneRegExp = /^\+?(972|0)(\-)?0?(([23489]{1}\d{7})|[5]{1}\d{8})$/;
// eslint-disable-next-line
const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
type FormData = {
	fullName?: string,
	phoneNumber?: string,
	email?: string,
	teacherSeniority?: string,
	teacherSubjects?: string,
	schoolName?: string,
	locality?: string,
	opinion?: string,
	promotingAcademicAchievement?: string,
	educationFor?: string,
	addressingDifferences?: string,
	personalExampleProviding?: string,
	uniqueContribution?: string,
	uniqueTeachingMethodsDevelopment?: string,
	recommenderFullName?: string,
	recommenderPhone?: string,
	recommenderEmail?: string,
	recommenderRelationship?: string,
	furtherRecommendFullName1?: string,
	furtherRecommendPhone1?: string,
	furtherRecommendFullName2?: string,
	furtherRecommendPhone2?: string,
	confirmCheckbox?: boolean | string,
};

type Props = {
	values: FormData,
	errors: FormData,
	fields: { [key: string]: { touched: boolean } },
	createItem: typeof createItem,
	resetForm: Function,
};

type State = {
	isUserNotified: boolean,
	isShowSenededFormPopup: boolean,
	isShowAlreadyInSystemPopup: boolean,
	isLoading: boolean,
};

const FORM_CONFIG = {
	form: 'userInfo',
	validate: (values: FormData) => {
		const errorsObj = {};

		if (!values.fullName) {
			errorsObj.fullName = ERRORS_TEXTS.fullName;
		}

		if (!values.phoneNumber) {
			errorsObj.phoneNumber = ERRORS_TEXTS.phoneNumber;
		} else if (values.phoneNumber) {
			const validPhone = phoneRegExp.test(values.phoneNumber.toLocaleLowerCase());
			if (!validPhone) {
				errorsObj.phoneNumber = ERRORS_TEXTS.phoneNumberNotValid;
			}
		}

		if (!values.email) {
			errorsObj.email = ERRORS_TEXTS.email;
		}

		// console.log(values);
		if (values.email) {
			// console.log('validEmail', validEmail);
			const validEmail = validateEmail(values.email.trim());
			if (!validEmail) {
				errorsObj.email = ERRORS_TEXTS.emailNotValid;
			}
		}

		if (!values.teacherSeniority) {
			errorsObj.teacherSeniority = ERRORS_TEXTS.teacherSeniority;
		}

		if (!values.teacherSubjects) {
			errorsObj.teacherSubjects = ERRORS_TEXTS.teacherSubjects;
		}

		if (!values.schoolName) {
			errorsObj.schoolName = ERRORS_TEXTS.schoolName;
		}

		if (!values.locality) {
			errorsObj.locality = ERRORS_TEXTS.locality;
		}

		if (!values.opinion) {
			errorsObj.opinion = ERRORS_TEXTS.opinion;
		} else if (values.opinion) {
			const opinionLength = wordCount(values.opinion);

			if (opinionLength > 500) {
				errorsObj.opinion = ERRORS_TEXTS.opinionMaximum;
			}
		}

		if (!values.recommenderFullName) {
			errorsObj.recommenderFullName = ERRORS_TEXTS.recommenderFullName;
		}

		if (!values.recommenderPhone) {
			errorsObj.recommenderPhone = ERRORS_TEXTS.recommenderPhone;
		} else if (values.recommenderPhone) {
			const validPhone = phoneRegExp.test(values.recommenderPhone.toLocaleLowerCase());
			if (!validPhone) {
				errorsObj.recommenderPhone = ERRORS_TEXTS.recommenderPhoneNotValid;
			}
		}

		if (!values.recommenderEmail) {
			errorsObj.recommenderEmail = ERRORS_TEXTS.recommenderEmail;
		} else if (values.recommenderEmail) {
			const validEmail = validator.isEmail(values.recommenderEmail.trim());

			if (!validEmail) {
				errorsObj.recommenderEmail = ERRORS_TEXTS.recommenderEmailNotValid;
			}
		}

		if (!values.recommenderRelationship) {
			errorsObj.recommenderRelationship = ERRORS_TEXTS.recommenderRelationship;
		}

		if (!values.confirmCheckbox) {
			errorsObj.confirmCheckbox = ERRORS_TEXTS.confirmCheckbox;
		}
		return errorsObj;
	},
};

const selectOpts1 = [
	{ id: 'שלוש שנים ומטה', label: 'שלוש שנים ומטה' },
	{ id: 'מעל לשלוש שנים', label: 'מעל לשלוש שנים' },
];

const selectOpts2 = [
	{ id: 'תלמיד/ה', label: 'תלמיד/ה' },
	{ id: 'הורה לתלמיד/ה', label: 'הורה לתלמיד/ה' },
	{ id: 'מורה עמית/ה', label: 'מורה עמית/ה' },
	{ id: 'מנהל/ת', label: 'מנהל/ת' },
];

const wordCount = value => {
	if (value === undefined) return 0;
	return value.trim().split(' ').length;
};
class ContentForm extends React.PureComponent<Props, State> {
	// static defaultProps = {
	// 	// className: '',
	// };

	constructor(props: Props) {
		super(props);
		this.state = {
			isUserNotified: false,
			isShowSenededFormPopup: false,
			isShowAlreadyInSystemPopup: false,
			isLoading: false,
		};
	}

	componentDidMount() {
		const isCreatedLead = localStorage.getItem('bestTeacher');

		if (isCreatedLead) {
			this.setState({
				isShowAlreadyInSystemPopup: true,
			});
		}
	}
	initFormValues = async () => {};

	onFormSubmit = async (values: FormData) => {
		const { resetForm } = this.props;

		this.setState({
			isUserNotified: false,
			isLoading: true,
		});

		const leadResult = await this.props.createItem<any>({ collection: COLLECTIONS.LEADS, data: values });

		if (leadResult.lead === LEAD_CONSTANTS.CREATED) {
			this.setState({
				isShowSenededFormPopup: true,
				isLoading: false,
			});

			localStorage.setItem('bestTeacher', 'true');

			resetForm('userInfo');

			dataLayerEvents.onFormSended(window.location.href);
		} else if (leadResult.lead === LEAD_CONSTANTS.FOUNDED) {
			this.setState({
				isShowAlreadyInSystemPopup: true,
				isLoading: false,
			});

			resetForm('userInfo');
		}
	};

	closeErrorPopup = () => {
		this.setState({
			isUserNotified: true,
			isLoading: false,
		});
	};

	closeAlreadyInSystemPopup = () => {
		this.setState({
			isShowAlreadyInSystemPopup: false,
		});
	};

	closeSuccessPopup = () => {
		this.setState({
			isShowSenededFormPopup: false,
		});
	};

	render() {
		const { errors, values, fields } = this.props;
		const { isShowSenededFormPopup, isShowAlreadyInSystemPopup } = this.state;
		// const isMobile = Responsive.isMatching(Responsive.MOBILE);
		const errosWithoutRadioBtns = _.omit(errors, [
			'promotingAcademicAchievement',
			'educationFor',
			'addressingDifferences',
			'personalExampleProviding',
			'uniqueContribution',
			'uniqueTeachingMethodsDevelopment',
		]);
		let macOs;

		// console.log('errors', errors);

		const opinionLength = values.opinion
			? values.opinion
					.toString()
					.trim()
					.split(/\s+/).length
			: '0';

		if (CAN_USE_DOM) {
			macOs = /(Mac)/i.test(navigator.platform);
		}

		const isErrors = !_.isEmpty(errors);

		const isErrorFullName = isErrors && !!_.get(errors, 'fullName', '');
		const isErrorTeacherPhoneNumber =
			isErrors && (!!_.get(errors, 'phoneNumber', '') || !!_.get(errors, 'phoneNumberNotValid', ''));
		const isErrorTeacherEmail = isErrors && (!!_.get(errors, 'email', '') || !!_.get(errors, 'emailNotValid', ''));
		const isErrorTeacherSeniority = isErrors && !!_.get(errors, 'teacherSeniority', '');
		const isErrorTeacherSubjects = isErrors && !!_.get(errors, 'teacherSubjects', '');
		const isErrorSchoolName = isErrors && !!_.get(errors, 'schoolName', '');
		const isErrorLocality = isErrors && !!_.get(errors, 'locality', '');
		const isErrorOpinion = isErrors && !!_.get(errors, 'opinion', '');

		const isErrorRecommenderFullName = isErrors && !!_.get(errors, 'recommenderFullName', '');
		const isErrorRecommenderPhone =
			isErrors && (!!_.get(errors, 'recommenderPhone', '') || !!_.get(errors, 'recommenderPhoneNotValid', ''));
		const isErrorRecommenderEmail =
			isErrors && (!!_.get(errors, 'recommenderEmail', '') || !!_.get(errors, 'recommenderEmailNotValid', ''));
		const isErrorRecommenderRelationship = isErrors && !!_.get(errors, 'recommenderRelationship', '');
		const isErrorCheckbox = isErrors && !!_.get(errors, 'confirmCheckbox', '');

		return (
			<div className={css.formWrapper}>
				<Form className={css.form} config={FORM_CONFIG} onSubmit={this.onFormSubmit}>
					<div className={css.title}>
						<h3>פרטי המורה עליו/עליה תרצו להמליץ</h3>
					</div>

					<div className={classNames(css.formRow, css.multipleInputs)}>
						<div className={css.fieldsWrapper}>
							<div
								className={classNames(
									css.fieldWrapper,
									css.require,
									isErrorFullName && _.get(fields, 'fullName.touched', false) && css.error
								)}
							>
								<TextField
									name="fullName"
									placeholder={PLACEHOLDERS_TEXTS.fullName}
									pattern="^[\u0590-\u05FF /]+$"
								/>
								{isErrorFullName && _.get(fields, 'fullName.touched', false) && (
									<p className={css.opinionLength}>{errors.fullName}</p>
								)}
							</div>
							<div
								className={classNames(
									css.fieldWrapper,
									css.require,
									isErrorTeacherPhoneNumber &&
										_.get(fields, 'phoneNumber.touched', false) &&
										css.error,
									css.withPhone
								)}
							>
								<TextField
									name="phoneNumber"
									placeholder={PLACEHOLDERS_TEXTS.phoneNumber}
									pattern="^[0-9-+]+$"
									isNumberField
									isPlusAtTheStart
									type="tel"
								/>
								{isErrorTeacherPhoneNumber && _.get(fields, 'phoneNumber.touched', false) && (
									<p className={css.opinionLength}>{errors.phoneNumber}</p>
								)}
							</div>
							<div
								className={classNames(
									css.fieldWrapper,
									css.require,
									isErrorTeacherEmail && _.get(fields, 'email.touched', false) && css.error
								)}
							>
								<TextField
									name="email"
									placeholder={PLACEHOLDERS_TEXTS.email}
									isEmailField
									type="email"
								/>
								{isErrorTeacherEmail && _.get(fields, 'email.touched', false) && (
									<p className={css.opinionLength}>{errors.email}</p>
								)}
							</div>
							<div
								className={classNames(
									css.fieldWrapper,
									css.require,
									isErrorTeacherSeniority &&
										_.get(fields, 'teacherSeniority.touched', false) &&
										css.error
								)}
							>
								<Select name="teacherSeniority" options={selectOpts1} placeholder="ותק של המורה" />
								{isErrorTeacherSeniority && _.get(fields, 'teacherSeniority.touched', false) && (
									<p className={css.opinionLength}>{errors.teacherSeniority}</p>
								)}
							</div>
						</div>
					</div>
					<div className={classNames(css.formRow, css.multipleInputs)}>
						<div className={css.fieldsWrapper}>
							<div
								className={classNames(
									css.fieldWrapper,
									css.longField,
									css.require,
									isErrorTeacherSubjects &&
										_.get(fields, 'teacherSubjects.touched', false) &&
										css.error
								)}
							>
								<TextField
									name="teacherSubjects"
									placeholder={PLACEHOLDERS_TEXTS.teacherSubjects}
									pattern="^[\u0590-\u05FF /]+$"
								/>
								{isErrorTeacherSubjects && _.get(fields, 'teacherSubjects.touched', false) && (
									<p className={css.opinionLength}>{errors.teacherSubjects}</p>
								)}
							</div>
							<div
								className={classNames(
									css.fieldWrapper,
									css.require,
									isErrorSchoolName && _.get(fields, 'schoolName.touched') && css.error
								)}
							>
								<TextField
									name="schoolName"
									placeholder={PLACEHOLDERS_TEXTS.schoolName}
									pattern="^[\u0590-\u05FF /]+$"
								/>
								{isErrorSchoolName && _.get(fields, 'schoolName.touched') && (
									<p className={css.opinionLength}>{errors.schoolName}</p>
								)}
							</div>
							<div
								className={classNames(
									css.fieldWrapper,
									css.require,
									isErrorLocality && _.get(fields, 'locality.touched') && css.error
								)}
							>
								<TextField
									name="locality"
									placeholder={PLACEHOLDERS_TEXTS.locality}
									pattern="^[\u0590-\u05FF /]+$"
								/>
								{isErrorLocality && _.get(fields, 'locality.touched') && (
									<p className={css.opinionLength}>{errors.locality}</p>
								)}
							</div>
						</div>
					</div>
					<div className={css.additionalText}>
						<p>
							כדי להקל על סינון המועמדים, נבקשך לכתוב הסבר מפורט (עד 500 מילים), בליווי דוגמאות, מדוע
							לדעתך המורה ראוי/ה לקבל את פרס "המורה של המדינה" בעבור תרומה יוצאת דופן שהטביעה את חותמה על
							תלמידיו/תלמידיה, על כלל בית הספר ועל הקהילה באחד או יותר מהקריטריונים הבאים:
						</p>
					</div>
					<div className={classNames(css.formRow)}>
						<div
							className={classNames(
								css.fieldWrapper,
								css.fullWidthField,
								isErrorOpinion && _.get(fields, 'opinion.touched') && css.error
							)}
						>
							<TextField
								name="opinion"
								label={PLACEHOLDERS_TEXTS.opinion}
								placeholder=""
								isTextarea
								textareaType={2}
								className={css.textAreaField}
								maxWordsLength={500}
								// pattern="^[\u0590-\u05FF 0-9 /\W\r\n-]+$"
								// maxLength={500}
							/>
							{isErrorOpinion && _.get(fields, 'opinion.touched') && (
								<p className={css.opinionLength}>{`${opinionLength} מילים`}</p>
							)}
							<p className={classNames(css.charCounter, opinionLength >= 500 && css.red)}>
								{opinionLength}/500
							</p>
						</div>
					</div>
					<div className={css.radioBtnsGroupTitle}>
						<p>דרג/י את המורה על פי הקריטריונים הבאים:</p>
						<p>(5 – במידה רבה מאוד, 1 – במידה מועטה מאוד)</p>
					</div>
					<div className={classNames(css.formRow)}>
						<div className={classNames(css.radioBtnsWrapper, css.radioBtnsWrapperWithTitle)}>
							<div
								className={classNames(
									css.radioBtnsWrapperTitle
									// isErrorPromotingAcademicAchievement && css.error
								)}
							>
								קידום הישגים לימודיים
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="promotingAcademicAchievement" value="5" label="5" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="promotingAcademicAchievement" value="4" label="4" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="promotingAcademicAchievement" value="3" label="3" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="promotingAcademicAchievement" value="2" label="2" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="promotingAcademicAchievement" value="1" label="1" />
							</div>
						</div>
					</div>
					<div className={classNames(css.formRow)}>
						<div className={classNames(css.radioBtnsWrapper, css.radioBtnsWrapperWithTitle)}>
							<div
								className={classNames(
									css.radioBtnsWrapperTitle
									//  isErrorEducationFor && css.error
								)}
							>
								חינוך לערכים חברתיים, לערכי תרבות, רב-תרבותיות ודמוקרטיה
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="educationFor" value="5" label="5" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="educationFor" value="4" label="4" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="educationFor" value="3" label="3" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="educationFor" value="2" label="2" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="educationFor" value="1" label="1" />
							</div>
						</div>
					</div>
					<div className={classNames(css.formRow)}>
						<div className={classNames(css.radioBtnsWrapper, css.radioBtnsWrapperWithTitle)}>
							<div
								className={classNames(
									css.radioBtnsWrapperTitle
									// isErrorAddressingDifferences && css.error
								)}
							>
								התייחסות לשונות בין התלמידים ומתן מענה אישי לכל תלמיד על-פי צרכיו
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="addressingDifferences" value="5" label="5" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="addressingDifferences" value="4" label="4" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="addressingDifferences" value="3" label="3" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="addressingDifferences" value="2" label="2" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="addressingDifferences" value="1" label="1" />
							</div>
						</div>
					</div>
					<div className={classNames(css.formRow)}>
						<div className={classNames(css.radioBtnsWrapper, css.radioBtnsWrapperWithTitle)}>
							<div
								className={classNames(
									css.radioBtnsWrapperTitle
									// isErrorPersonalExampleProviding && css.error
								)}
							>
								מתן דוגמה אישית ותרומה ייחודית לעיצוב האישיות של תלמידיו
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="personalExampleProviding" value="5" label="5" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="personalExampleProviding" value="4" label="4" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="personalExampleProviding" value="3" label="3" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="personalExampleProviding" value="2" label="2" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="personalExampleProviding" value="1" label="1" />
							</div>
						</div>
					</div>
					<div className={classNames(css.formRow)}>
						<div className={classNames(css.radioBtnsWrapper, css.radioBtnsWrapperWithTitle)}>
							<div
								className={classNames(
									css.radioBtnsWrapperTitle
									// isErrorUniqueContribution && css.error
								)}
							>
								תרומה ייחודית לרווחת התלמידים ולתחושת הביטחון והרציפות הקשר האישי בעת הקורונה
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="uniqueContribution" value="5" label="5" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="uniqueContribution" value="4" label="4" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="uniqueContribution" value="3" label="3" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="uniqueContribution" value="2" label="2" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="uniqueContribution" value="1" label="1" />
							</div>
						</div>
					</div>
					<div className={classNames(css.formRow)}>
						<div className={classNames(css.radioBtnsWrapper, css.radioBtnsWrapperWithTitle)}>
							<div
								className={classNames(
									css.radioBtnsWrapperTitle
									// isErrorUniqueTeachingMethodsDevelopment && css.error
								)}
							>
								פיתוח דרכי הוראה ייחודיות במסגרת הלמידה מרחוק
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="uniqueTeachingMethodsDevelopment" value="5" label="5" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="uniqueTeachingMethodsDevelopment" value="4" label="4" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="uniqueTeachingMethodsDevelopment" value="3" label="3" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="uniqueTeachingMethodsDevelopment" value="2" label="2" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="uniqueTeachingMethodsDevelopment" value="1" label="1" />
							</div>
						</div>
					</div>
					<div className={classNames(css.title, css.withTopPadding)}>
						<h3>פרטי הממליצ/ה</h3>
					</div>
					<div className={classNames(css.formRow, css.multipleInputs)}>
						<div className={css.fieldsWrapper}>
							<div
								className={classNames(
									css.fieldWrapper,
									css.require,
									isErrorRecommenderFullName &&
										_.get(fields, 'recommenderFullName.touched') &&
										css.error
								)}
							>
								<TextField
									name="recommenderFullName"
									placeholder={PLACEHOLDERS_TEXTS.recommenderFullName}
									pattern="^[\u0590-\u05FF /]+$"
								/>
								{isErrorRecommenderFullName && _.get(fields, 'recommenderFullName.touched') && (
									<p className={css.opinionLength}>{errors.recommenderFullName}</p>
								)}
							</div>
							<div
								className={classNames(
									css.fieldWrapper,
									css.require,
									isErrorRecommenderPhone && _.get(fields, 'recommenderPhone.touched') && css.error,
									css.withPhone
								)}
							>
								<TextField
									name="recommenderPhone"
									placeholder={PLACEHOLDERS_TEXTS.recommenderPhone}
									pattern="[0-9-+]"
									isNumberField
									isPlusAtTheStart
									type="tel"
								/>
								{isErrorRecommenderPhone && _.get(fields, 'recommenderPhone.touched') && (
									<p className={css.opinionLength}>{errors.recommenderPhone}</p>
								)}
							</div>
							<div
								className={classNames(
									css.fieldWrapper,
									css.require,
									isErrorRecommenderEmail && _.get(fields, 'recommenderEmail.touched') && css.error
								)}
							>
								<TextField
									name="recommenderEmail"
									placeholder={PLACEHOLDERS_TEXTS.recommenderEmail}
									isEmailField
									type="email"
								/>
								{isErrorRecommenderEmail && _.get(fields, 'recommenderEmail.touched') && (
									<p className={css.opinionLength}>{errors.recommenderEmail}</p>
								)}
							</div>
							<div
								className={classNames(
									css.fieldWrapper,
									css.require,
									isErrorRecommenderRelationship &&
										_.get(fields, 'recommenderRelationship.touched') &&
										css.error
								)}
							>
								<Select
									name="recommenderRelationship"
									options={selectOpts2}
									placeholder="מהו הקשר שלך למורה"
								/>
								{isErrorRecommenderRelationship && _.get(fields, 'recommenderRelationship.touched') && (
									<p className={css.opinionLength}>{errors.recommenderRelationship}</p>
								)}
							</div>
						</div>
					</div>
					<div className={classNames(css.title, css.withPaddings)}>
						<h3>ממליצים נוספים</h3>
						<p>
							אנו מעוניינים לדעת עוד על אחד ואחת מהמועמדים לקבלת הפרס, ולשם כך מבקשים ליצור קשר עם אנשים
							נוספים המכירים ומוקירים את עבודתם הורים, עמיתים, מנהלים ואחרים.
						</p>
					</div>
					<div className={classNames(css.formRow, css.multipleInputs)}>
						<div className={css.fieldsWrapper}>
							<div className={classNames(css.fieldWrapper)}>
								<TextField
									name="furtherRecommendFullName1"
									placeholder={PLACEHOLDERS_TEXTS.furtherRecommendFullName1}
									pattern="^[\u0590-\u05FF /]+$"
								/>
							</div>
							<div className={classNames(css.fieldWrapper, css.withPhone)}>
								<TextField
									name="furtherRecommendPhone1"
									placeholder={PLACEHOLDERS_TEXTS.furtherRecommendPhone1}
									pattern="[0-9-+]"
									isNumberField
									isPlusAtTheStart
									type="tel"
								/>
							</div>
							<div className={classNames(css.fieldWrapper)}>
								<TextField
									name="furtherRecommendFullName2"
									placeholder={PLACEHOLDERS_TEXTS.furtherRecommendFullName2}
									pattern="^[\u0590-\u05FF /]+$"
								/>
							</div>
							<div className={classNames(css.fieldWrapper, css.withPhone)}>
								<TextField
									name="furtherRecommendPhone2"
									placeholder={PLACEHOLDERS_TEXTS.furtherRecommendPhone2}
									pattern="^[0-9-+]+$"
									isNumberField
									isPlusAtTheStart
									type="tel"
								/>
							</div>
						</div>
					</div>
					<div className={css.formRow}>
						<div className={classNames(css.checkboxWrapper)}>
							<Checkbox
								name="confirmCheckbox"
								label={
									// !isMobile ? (
									<>
										אני מסכים/ה{' '}
										<a href={termsPdfFile} target="_blank" rel="noreferrer">
											לתקנון{' '}
										</a>
										,{' '}
										<a
											href="https://z.ynet.co.il/short/content/2018/privacypolicy/terms.html"
											target="_blank"
											rel="noreferrer"
										>
											תנאי השימוש{' '}
										</a>
										,
										<a
											href="https://z.ynet.co.il/short/content/2018/privacypolicy/policy.html
"
											target="_blank"
											rel="noreferrer"
										>
											ומדיניות הפרטיות
										</a>{' '}
										ולקבלת פניות שיווקיות כולל טלפוניות מ-ynet הנ"ל איננה הסכמה לעריכת עסקה.
									</>
								}
							/>
							{isErrorCheckbox && _.get(fields, 'confirmCheckbox.touched') && (
								<p className={css.error}>{errors.confirmCheckbox}</p>
							)}
							<p className={css.checkBoxTextRequire}>שדות חובה</p>
						</div>
					</div>
					<div className={classNames(css.formRow, css.withButton)}>
						<button type="submit" className={classNames(css.submitBtn, !macOs && css.forWindows)}>
							<div className={css.submitButtonInner}>
								{this.state.isLoading ? (
									<Icon type="preloader" className={css.preloader} />
								) : (
									<>
										<span>שליחה</span>
										<img src={arrowLeft} alt="Submit" className={css.arrow} />
									</>
								)}
							</div>
						</button>
					</div>
				</Form>

				{/* <ErrorsPopup
					className={!isUserNotified && !_.isEmpty(errors) ? 'show' : ''}
					closePopup={this.closeErrorPopup}
					errors={errosWithoutRadioBtns}
				/> */}
				<AlreadyInSystemPopup
					className={isShowAlreadyInSystemPopup ? 'show' : ''}
					closePopup={this.closeAlreadyInSystemPopup}
				/>
				<NotStartedPopup className="" />
				<SendedFormPopup className={isShowSenededFormPopup ? 'show' : ''} closePopup={this.closeSuccessPopup} />
			</div>
		);
	}
}

const mapState = (state: ClientStore) => {
	const values = 'form.userInfo.values';
	const errors = 'form.userInfo.syncErrors';
	const fields = 'form.userInfo.fields';

	return {
		values: _.get(state, `${values}`, {}),
		errors: _.get(state, `${errors}`, {}),
		fields: _.get(state, `${fields}`, {}),
	};
};

const mapDispatch = {
	initializeForm: initialize,
	createItem,
	resetForm: reset,
};

export default connect(mapState, mapDispatch)(ContentForm);
