/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import classNames from 'classnames';
import { Header, Footer, Responsive } from 'client/components/common';
import { type EnhancedProps, withTransition } from 'client/components/common/HOCs/with-transition';

import bg from 'assets/client/testImages/main-bg-desktop1.png';
import bgMob from 'assets/client/testImages/mobile_bg1.png';
import contentLogo from 'assets/client/testImages/content-logo1.png';
import title from 'assets/client/testImages/title1.png';
import subTitle from 'assets/client/testImages/subtitle1.png';
import titleMob from 'assets/client/testImages/title-mob1.png';

import ClosedActivityPopup from 'client/components/common/Popups/ClosedActivityPopup';
import ContentForm from 'client/components/common/ContentForm';
import * as css from './Intro.scss';

type Props = {|
	...EnhancedProps,
	className?: string,
	in: boolean,
|};

type State = {};
class Intro extends React.PureComponent<Props, State> {
	transitionCompleteTimeout: TimeoutID;

	static defaultProps = {
		className: '',
	};

	state = {};

	componentDidMount() {}

	componentDidUpdate(prevProps: Props) {
		if (this.props.in !== prevProps.in) {
			const { onTransitionComplete } = this.props;
			let duration = parseInt(this.props.in ? css.transitionIn : css.transitionOut, 10);

			if (!this.props.in) {
				duration = 0;
			}
			clearTimeout(this.transitionCompleteTimeout);
			this.transitionCompleteTimeout = setTimeout(onTransitionComplete, duration);
		}
	}

	render() {
		const { className, transitionState } = this.props;
		const isMobile = Responsive.isMatching(Responsive.MOBILE);

		return (
			<div className={classNames(css.intro, css[transitionState], className)} key="main">
				<Header isMobile={Responsive.isMatching(Responsive.MOBILE)} />
				<div className={css.content} style={{ backgroundImage: `url(${!isMobile ? bg : bgMob})` }}>
					<div className={css.logoWrapper}>
						<img src={contentLogo} alt="content-logo" />
					</div>
					<div className={css.titleWrapper}>
						<img src={isMobile ? titleMob : title} alt="title" />
					</div>
					<div className={css.text1}>
						<p>
							הסתדרות המורים, בנק מסד, "ידיעות אחרונות" ו-ynet מזמינים אתכם להמליץ על המורים הטובים
							והאהובים ביותר, אלה ששינו את חייכם או את חיי ילדיכם, ולהעניק להם את הכבוד וההערכה הראויים
							להם.
						</p>
						<p>
							תלמידים, הורים, מורים ומנהלים - המליצו לנו על המועמדים המצטיינים שלכם, ואולי הם יהיו חלק
							מהנבחרת של תחרות "המורה של המדינה".
						</p>
						<p>
							המורים יעברו תהליך הערכה ומיון ובסיומו תיבחר ועדה המורכבת מאנשי ציבור מובילים את 6 המורים
							המצטיינים של ישראל לשנת 2023.
						</p>
					</div>
					<div className={css.subtitleWrapper}>
						<img src={subTitle} alt="title" />
					</div>
					<div className={css.text2}>
						<p>
							כל 15 המועמדים שיגיעו לגמר יקבלו תעודת הערכה ומחשב נייד מתנת בנק מסד. ששת המורים הזוכים
							יזכו, בנוסף, בהשתלמות יוקרתית בארה"ב מתנת תלמ"א - תוכנית לאומית למצוינות באנגלית.
						</p>
					</div>
				</div>
				<ContentForm />
				<Footer />
				{/* <ClosedActivityPopup className="show" /> */}
			</div>
		);
	}
}

export default withTransition(Intro);
