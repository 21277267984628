import React, { useEffect, useState } from 'react';

import TeachersForm from '../../common/TeachersForm';
import { PasswordForm } from '../../common/PasswordForm';

import css from './TeachersForm.module.scss';

const realPassword = 'Morim14medina';

export const TeachersPage = () => {
	const [password, setPassword] = useState('');
	const [isFormOpened, setIsFormOpened] = useState(false);
	const [isError, setIsError] = useState(false);

	useEffect(() => {
		if (password === realPassword) {
			localStorage.setItem('isLoggedIn', true);
			setIsFormOpened(true);
			setIsError(false);
		} else if (password) {
			setIsError(true);
		}
	}, [password]);

	useEffect(() => {
		if (localStorage.getItem('isLoggedIn')) {
			setIsFormOpened(true);
		}
	}, []);

	return (
		<div className={css.container}>
			{isFormOpened ? <TeachersForm /> : <PasswordForm onSubmit={setPassword} isError={isError} />}
		</div>
	);
};
