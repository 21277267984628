// @flow

import * as React from 'react';
import classNames from 'classnames';

import { share } from 'client/utils/share';
import { IS_DEVEL } from 'common/constants';
import { dataLayerEvents } from 'common/utils/gtag';
import { SHARE_CONSTANTS } from 'client/constants';
import { Icon } from '../Icon';

import * as css from './Socials.scss';

type Props = {
	className?: string,
	minified?: boolean,
	// shareCategory: string,
};

type State = {
	isOpen: boolean,
};

type ReactObjRef = { current: HTMLDivElement | null };
export default class Socials extends React.Component<Props, State> {
	sociallsRef: ReactObjRef;
	static defaultProps = {
		className: '',
		minified: false,
	};

	state = {
		isOpen: false,
	};

	constructor(props: Props) {
		super(props);
		this.state = {
			isOpen: false,
		};
		this.sociallsRef = React.createRef();
	}

	componentDidMount() {
		document.addEventListener('click', this.documentClickListener);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.documentClickListener);
	}

	documentClickListener = (e: MouseEvent) => {
		if (this.sociallsRef.current) {
			if (this.sociallsRef.current.contains((e.target: any))) return false;
		}

		this.setState({
			isOpen: false,
		});
	};

	toggleOpenMenu = (e: SyntheticEvent<HTMLDivElement>) => {
		this.setState(prevState => ({
			isOpen: !prevState.isOpen,
		}));
	};

	onShare = (e: SyntheticEvent<HTMLButtonElement>) => {
		const type = e.currentTarget.dataset.id;
		// events.onShareClick(type, this.props.shareCategory);
		if (!IS_DEVEL) {
			dataLayerEvents.onShareBtnClick(type);
			share(type);
		}
	};

	render() {
		const { className, minified } = this.props;
		const { isOpen } = this.state;

		return (
			<div
				className={classNames(css.socials, this.state.isOpen && css.open, minified && css.minified, className)}
				ref={this.sociallsRef}
			>
				<div className={css.overflowWrap}>
					<button
						type="button"
						className={css.socialButton}
						data-id={SHARE_CONSTANTS.WHATSAPP}
						onClick={this.onShare}
					>
						<Icon type="whatsapp" width={32} height={32} className={css.iconWhatsapp} />
					</button>
					<button
						className={css.socialButton}
						type="button"
						data-id={SHARE_CONSTANTS.FACEBOOK}
						onClick={this.onShare}
					>
						<Icon type="facebook" width={32} height={32} className={css.iconFacebook} />
					</button>
				</div>
				{minified && (
					<div className={`${css.toggleBtn} ${isOpen ? css.open : ''}`} onClick={this.toggleOpenMenu}>
						<Icon type="share" width={35} height={35} color="#ff7722" className={`${css.iconToggle} `} />
					</div>
				)}
			</div>
		);
	}
}
