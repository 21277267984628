import React, { FC } from 'react';
import { Field } from 'redux-form';
import { Picker } from './Picker';

import css from './DatePicker.module.scss';

type Props = {
	name: string,
	label: string,
	defaultValue: null,
	placeholder: string,
};

const DatePicker: FC<Props> = props => {
	return <Field {...props} component={Picker} className={css.pickerWrapper} />;
};

export default DatePicker;
